import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { COLOR } from '@/app/common/style/variables';
import { rem } from '@/app/common/style/functions';
import { desktopAndBelow } from '../common/style/mixins';

const SpeakerStyle = css`
  .speaker-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .speaker-photo {
      overflow: auto;
      border-radius: 50%;
      border: 2px solid #ce815a;
      width: 200px;
      height: 200px;

      ${desktopAndBelow(css`
        width: auto;
        height: auto;
      `)}
    }

    .speaker-details {
      margin-left: 40px;
      display: table-cell;
      vertical-align: middle;

      ${desktopAndBelow(css`
        margin-left: 12px;
      `)}

      h5 {
        font-size: ${rem(26)};
        color: ${COLOR.green1};
        font-weight: 500;
        margin-bottom: 8px;
      }

      p {
        font-size: ${rem(18)};
      }
    }
  }
`;

const SpeakerStyled = styled.div`
  ${SpeakerStyle}
`;

export default SpeakerStyled;
