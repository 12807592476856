import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';
import InsightsSingleStyled from '@/components/styled/InsightsSingleStyled';
import { IContentfulBlogProps } from '@/types';
import helpers from '@/utils/helpers';
import ContentfulRawToComponent from '@/components/utils/ContentfulRawToComponent';
import { usePageMeta } from '@/components/app/hooks';
import Reveal from '@/components/reveal/Reveal';
import EventsSingleStyled from '@/components/styled/EventsSingleStyled';
import Speaker from '@/app/blog/Speaker';

const Event = ({ data, override, overrideContent, location }) => (
  <EventsSingleStyled>
    {usePageMeta(data.contentfulBlogPost?.meta)}

    <div className="content single-article-page">
      <div className="container">
        <div className="page-title link-title">
          <Link className="link back" to="/notes">
            All notes
          </Link>
        </div>

        <div className="post-header">
          <Reveal className="date-container">
            {!!data.contentfulBlogPost.category?.[0] && (
              <p className="category">{data.contentfulBlogPost.category[0]}</p>
            )}
            <span className="separator" />
            <p className="date">
              {helpers.contentfulDate(data.contentfulBlogPost.createdAt)}
            </p>
          </Reveal>
          <Reveal className="main-details">
            <div className="image-container">
              {!!data.contentfulBlogPost.image && (
                <GatsbyImage
                  image={getImage(
                    data.contentfulBlogPost.image?.gatsbyImageData,
                  )}
                  alt={data.contentfulBlogPost.title || ``}
                />
              )}
            </div>
          </Reveal>
        </div>

        <div className="post-container">
          <div className="title-container">
            <h1>{data.contentfulBlogPost.title}</h1>
            <p className="tagline">{data.contentfulBlogPost.subtitle}</p>
          </div>

          <div className="content">
            {!!data.contentfulBlogPost.introduction && (
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.introduction.raw}
                options={{
                  renderNode: {
                    ...override.renderNode,
                    ...overrideContent.renderNode,
                  },
                }}
              />
            )}

            {!!data.contentfulBlogPost.speakerContents &&
              data.contentfulBlogPost.speakerContents?.map((speaker) => (
                <div className="speaker-cards">
                  <Speaker speaker={speaker.speakerPhotos} />
                  <div className="speaker-content">
                    <h3>{speaker.title}</h3>
                    <ContentfulRawToComponent
                      raw={speaker.content.raw}
                      options={{
                        renderNode: {
                          ...override.renderNode,
                          ...overrideContent.renderNode,
                        },
                      }}
                    />
                  </div>
                </div>
              ))}

            {!!data.contentfulBlogPost.content && (
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.content.raw}
                options={{
                  renderNode: {
                    ...override.renderNode,
                    ...overrideContent.renderNode,
                  },
                }}
              />
            )}
          </div>

          <Reveal className="post-footer">
            <span className="share-text">Share this article</span>
            <div className="share">
              <LinkedinShareButton
                className="link linkedin"
                url={location.href}
              >
                LinkedIn
              </LinkedinShareButton>
              <TwitterShareButton className="link twitter" url={location.href}>
                Twitter
              </TwitterShareButton>
              <FacebookShareButton
                url={location.href}
                className="link facebook"
              >
                Facebook
              </FacebookShareButton>
            </div>

            <div className="link-container">
              <Link className="link back" to="/notes">
                All notes
              </Link>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  </EventsSingleStyled>
);

const CareersCta = () => (
  <div className="careers-cta content">
    <div>
      <h4>Curious about IONA?</h4>
      <p>
        Join our team of future-thinkers, strategic pathfinders and
        technological explorers.
      </p>
    </div>

    <div>
      <Link className="btn" to="/careers/">
        Careers
      </Link>
    </div>
  </div>
);

const Article = ({ data, override, overrideContent, location }) => (
  <InsightsSingleStyled>
    {usePageMeta(data.contentfulBlogPost?.meta)}
    <div className="content single-article-page">
      <div className="container">
        <div className="page-title link-title">
          <Link className="link back" to="/notes">
            All notes
          </Link>
        </div>

        <div className="post-header">
          <Reveal className="date-container">
            {!!data.contentfulBlogPost.category?.[0] && (
              <p className="category">{data.contentfulBlogPost.category[0]}</p>
            )}
            <span className="separator" />
            <p className="date">
              {helpers.contentfulDate(data.contentfulBlogPost.createdAt)}
            </p>
          </Reveal>
          <Reveal className="main-details">
            <div className="image-container">
              {!!data.contentfulBlogPost.image && (
                <GatsbyImage
                  image={getImage(
                    data.contentfulBlogPost.image?.gatsbyImageData,
                  )}
                  alt={data.contentfulBlogPost.title || ``}
                />
              )}
            </div>
            <div className="title-container">
              <h1>{data.contentfulBlogPost.title}</h1>
            </div>
          </Reveal>
        </div>

        <div className="post-container">
          {!!data.contentfulBlogPost.introduction && (
            <Reveal className="intro">
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.introduction.raw}
              />
            </Reveal>
          )}
          {!!data.contentfulBlogPost.content && (
            <div className="content">
              <ContentfulRawToComponent
                raw={data.contentfulBlogPost.content.raw}
                options={{
                  renderNode: {
                    ...override.renderNode,
                    ...overrideContent.renderNode,
                  },
                }}
              />
            </div>
          )}

          {data.contentfulBlogPost?.careersCta && <CareersCta />}

          {data.contentfulBlogPost?.whitepaper?.image?.image &&
            data.contentfulBlogPost?.whitepaper?.file?.file && (
              <Reveal className="whitepaper-container">
                <div className="whitepaper-image">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost?.whitepaper?.image?.image
                          .gatsbyImageData,
                      )}
                      alt={data.contentfulBlogPost?.whitepaper?.title || ``}
                    />
                  </div>
                </div>

                <div className="whitepaper-button">
                  <div className="download-description">
                    <div>Want to know more?</div>
                    <div>Download our whitepaper.</div>
                  </div>
                  <Link
                    className="btn"
                    to={`/notes/${data.contentfulBlogPost.slug}/whitepaper/${data.contentfulBlogPost?.whitepaper.slug}`}
                  >
                    <div className="button-container">
                      DOWNLOAD THE WHITEPAPER
                    </div>
                  </Link>
                </div>
              </Reveal>
            )}

          {data.contentfulBlogPost.contactMessage &&
            data.contentfulBlogPost.author && (
              <div className="author-container">
                <Reveal className="description">
                  <ContentfulRawToComponent
                    raw={data.contentfulBlogPost.contactMessage.raw}
                    options={override}
                  />
                </Reveal>

                <Reveal className="author-details">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost.author.photo.gatsbyImageData,
                      )}
                      alt={`${data.contentfulBlogPost.author.firstName} ${data.contentfulBlogPost.author.lastName} Photo`}
                    />
                  </div>

                  <div className="about-author">
                    <p className="name">
                      {data.contentfulBlogPost.author.firstName}
                      {` `}
                      {data.contentfulBlogPost.author.lastName}
                    </p>
                    <p className="position">
                      {data.contentfulBlogPost.author.designation}
                    </p>

                    <a
                      className="email"
                      href={`mailto:${data.contentfulBlogPost.author.emailAddress}`}
                    >
                      {data.contentfulBlogPost.author.emailAddress}
                    </a>
                  </div>
                </Reveal>
              </div>
            )}

          {data.contentfulBlogPost?.bookIntroCta &&
            data.contentfulBlogPost?.author && (
              <div className="author-container">
                <Reveal className="author-details">
                  <div className="image-container">
                    <GatsbyImage
                      image={getImage(
                        data.contentfulBlogPost?.author?.photo?.gatsbyImageData,
                      )}
                      alt={`${data.contentfulBlogPost?.author?.firstName} ${data.contentfulBlogPost?.author?.lastName} Photo`}
                    />
                  </div>

                  <div className="about-author">
                    <p className="name">
                      {data.contentfulBlogPost?.author?.firstName}
                      {` `}
                      {data.contentfulBlogPost?.author?.lastName}
                    </p>
                    <p className="position">
                      {data.contentfulBlogPost?.author?.designation}
                    </p>

                    <a
                      className="email"
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${data.contentfulBlogPost?.author?.emailAddress}`}
                    >
                      {data.contentfulBlogPost?.author?.emailAddress}
                    </a>
                  </div>
                </Reveal>

                <Reveal className="book-intro">
                  <Link
                    className="btn"
                    to={data.contentfulBlogPost?.author?.calendlyUrl}
                  >
                    {data.contentfulBlogPost?.author?.bookingButtonText ||
                      `Book a 30 min intro`}
                  </Link>
                </Reveal>
              </div>
            )}

          <Reveal className="post-footer">
            <span className="share-text">Share this article</span>
            <div className="share">
              <LinkedinShareButton
                className="link linkedin"
                url={location.href}
              >
                LinkedIn
              </LinkedinShareButton>
              <TwitterShareButton className="link twitter" url={location.href}>
                Twitter
              </TwitterShareButton>
              <FacebookShareButton
                url={location.href}
                className="link facebook"
              >
                Facebook
              </FacebookShareButton>
            </div>

            <div className="link-container">
              <Link className="link back" to="/notes">
                All notes
              </Link>
            </div>
          </Reveal>
        </div>
      </div>
    </div>
  </InsightsSingleStyled>
);

const ContentfulBlog: React.FC<IContentfulBlogProps> = ({ data, location }) => {
  const richTextImages = {};
  const imagesList = data.contentfulBlogPost.content?.references || [];
  if (imagesList.length) {
    data.contentfulBlogPost.content?.references.map((image) => {
      const { contentfulId } = image;
      richTextImages[contentfulId] = {
        image: image.gatsbyImageData,
        description: image.description,
        alt: image.title,
      };
      return image;
    });
  }

  const override = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, _next) => {
        const { data: payload } = node;
        const dataImages = richTextImages;
        const src = dataImages[payload.target.sys.id];
        return (
          <div className="image-container">
            <GatsbyImage image={src?.image || ``} alt={src?.alt || ``} />
            {src?.description !== `` && <span>{src?.description}</span>}
          </div>
        );
      },
      // eslint-disable-next-line no-empty-pattern
      [INLINES.HYPERLINK]: ({}, children) => {
        if (children && children.length && children[0][1] !== ``) {
          return (
            <a href={`mailto:${data.contentfulBlogPost.author.emailAddress}`}>
              {children}
            </a>
          );
        }
        return <></>;
      },
    },
  };

  const overrideContent = {
    renderNode: {
      [INLINES.HYPERLINK]: ({ data: { uri } }, children) => {
        if (!uri.includes(`youtube.com`)) {
          return (
            <Link className="link" to={uri} aria-label={`View ${children}`}>
              {children}
            </Link>
          );
        }

        const match =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(
            uri,
          );
        const videoId = match && match[7].length === 11 ? match[7] : null;
        return (
          videoId && (
            <section className="video-container">
              <iframe
                className="video"
                title={`https://youtube.com/embed/${videoId}`}
                src={`https://youtube.com/embed/${videoId}`}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </section>
          )
        );
      },
    },
  };

  const articleType = data.contentfulBlogPost.type;

  return articleType === `event`
    ? Event({ data, override, location, overrideContent })
    : Article({
        data,
        override,
        overrideContent,
        location,
      });
};

export const query = graphql`
  query Insight($id: String) {
    contentfulBlogPost(id: { eq: $id }) {
      slug
      title
      subtitle
      category
      type
      author {
        photo {
          gatsbyImageData
        }
        firstName
        lastName
        designation
        emailAddress
        calendlyUrl
      }
      content {
        raw
        references {
          gatsbyImageData
          description
          contentfulId: contentful_id
          title
        }
      }
      introduction {
        raw
      }
      speakerContents {
        title
        speakerPhotos {
          title
          description
          gatsbyImageData
        }
        content {
          raw
        }
      }
      contactMessage {
        raw
      }
      excerpt {
        excerpt
      }
      createdAt
      image {
        gatsbyImageData
      }
      whitepaper {
        title
        slug
        image {
          image {
            gatsbyImageData
          }
        }
        file {
          file {
            url
            contentType
            fileName
            details {
              size
            }
          }
        }
      }
      category
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
      careersCta
      bookIntroCta
    }
  }
`;

export default ContentfulBlog;
