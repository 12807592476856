import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import SpeakerStyled from './SpeakerStyled';

interface Props {
  speaker: Array<{
    title: string;
    description: string;
    gatsbyImageData: ImageDataLike;
  }>;
}

const Speaker: React.FC<Props> = ({ speaker }) => (
  <SpeakerStyled>
    {speaker.map((data) => (
      <div className="speaker-container">
        <div className="speaker-photo">
          <GatsbyImage
            image={getImage(data.gatsbyImageData)}
            alt={data.title || ``}
          />
        </div>
        <div className="speaker-details">
          <h5>{data.title}</h5>
          <p>{data.description}</p>
        </div>
      </div>
    ))}
  </SpeakerStyled>
);
export default Speaker;
